















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class BaseBtn extends Vue {
  @Prop({
    type: [String, Array],
    default: 'sm xl',
  }) padding!: string | []

  @Prop({
    type: Boolean,
    default: false,
  }) disable!: boolean
}
